import React, { lazy, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import httpCodes from 'web/mslm/httpCodes.ts';
import fileHttpCodes from 'web/mslm/fileHttpCodes.ts';
import scmsHttpCodes from 'web/mslm/scmsHttpCodes.ts';
import hrmsHttpCodes from 'web/mslm/hrmsHttpCodes.ts';
import env from 'web/mslm/env.ts';

import ErrorBoundary from 'dsl_web/components/ErrorBoundary.tsx';
import GlobalLoader from 'dsl_web/components/Loader.tsx';

import store from 'web/mslm/cloud/redux/store.ts';

const App = lazy(() => import('web/mslm/App.tsx'));

global.env = env;
global.httpCodes = httpCodes;
global.fileHttpCodes = fileHttpCodes;
global.scmsHttpCodes = scmsHttpCodes;
global.hrmsHttpCodes = hrmsHttpCodes;

const container = document.getElementById('root')!;
const root = createRoot(container);
root.render(
    <React.StrictMode>
        <ErrorBoundary>
            <Provider store={store}>
                <Suspense fallback={<GlobalLoader fillScreen />}>
                    <App />
                </Suspense>
            </Provider>
        </ErrorBoundary>
    </React.StrictMode>
);
